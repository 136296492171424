import Alpine from "alpinejs"

import "shared/unpoly"
import "shared/uppy"

import "carrier/styles/main.css"
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

up.compiler("#datepicker", function(el) {
  var date = new Date();
  flatpickr(el, {
    maxDate: date.setDate(date.getDate() - 1),
    disableWeekends: true,
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    "locale": {
        "firstDayOfWeek": 1 // start week on Monday
    }
  });
})

window.Alpine = Alpine;
document.addEventListener("DOMContentLoaded", function(event) {
  Alpine.start()
});